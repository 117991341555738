<template>
  <div
    ref="epidemicList"
    class="epidemicList"
    :class="{
      'padding-top': !isAdmin,
    }"
  >
    <template v-if="!isAdmin">
      <div class="header">
        <v-dropDownMenu
          v-model="requestData.districtName"
          :option1="districtList"
          @change="change"
        ></v-dropDownMenu>
        <div class="header-sum">
          <div class="sum-right">
            <img src="./img/stationOpenCount.png" alt="" />
            <span class="label">已开放：</span>
            <span class="value" style="color: #367bf4ff">
              {{ stationOpenCount }}
            </span>
          </div>
          <div class="sum-left">
            <img src="./img/stationCloseCount.png" alt="" />
            <span class="label">未开放：</span>
            <span class="value">{{ stationCloseCount }}</span>
          </div>
        </div>
      </div>
    </template>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :immediateCheck="false"
      :finishedText="finishedText"
    >
      <div class="list">
        <div
          class="section"
          v-for="(item, index) in listData"
          :key="index"
          @click="toEpidemicDetail(item)"
        >
          <div class="section-left">
            <div
              class="left-title"
              :style="{
                color:
                  item.isOpen === 0
                    ? 'rgba(0, 0, 0, 0.5)'
                    : 'rgba(0, 0, 0, 0.85)',
              }"
            >
              {{ item.name }}
            </div>
            <div class="left-addr">
              <span>地址：</span><span>{{ item.addr }}</span>
            </div>
            <div class="left-openTime">
              <span>时间：</span><span>{{ item.openTime }}</span>
            </div>
            <div class="left-testType" @click.stop="chooseTestType(item)">
              <span>类型：</span><span>{{ item.testType }}</span>
              <img src="./img/edit.png" v-show="isAdmin" />
            </div>
          </div>
          <div class="distance" v-show="!isAdmin">
            <span>距离：</span>
            <span
              >{{
                item.distance || item.distance === 0
                  ? `${(item.distance / 1000).toFixed(2)}公里`
                  : "暂无"
              }}
            </span>
          </div>
          <div class="section-right">
            <template v-if="isAdmin || item.isOpen !== 0">
              <div class="right-label"><span>排队预计时长</span></div>
              <!-- 
              :style="{
                backgroundColor: colorMap[item.waitingTime],
                color: item.waitingTime === 3 ? '#fff' : 'rgba(0, 0, 0, 0.85)',
              }" -->
              <div
                class="right-waitingTime"
                :style="setStyle(item)"
                @click.stop="chooseWaitingTime(item)"
              >
                <span>{{ waitingTimeMap[item.waitingTime] }}</span>
                <img src="./img/edit.png" v-show="isAdmin" />
              </div>
            </template>
            <div
              class="right-open"
              @click.stop="chooseIsOpen(item)"
              v-show="isAdmin"
            >
              <span>站点{{ isOpenMap[item.isOpen] }}</span>
              <img src="./img/edit.png" />
            </div>
            <div class="right-no-open" v-show="!isAdmin && item.isOpen === 0">
              <img src="./img/unopen.png" />
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <v-picker
      :columns="waitingTimeOps"
      :valueShow="showPicker"
      :defaultIndex="curItem.waitingTime - 1"
      :valueKey="'text'"
      @clickOverlay="clickWaitingTimeOverlay"
      @cancel="cancelWaitingTime"
      @confirmPicker="confirmWaitingTime"
    ></v-picker>

    <v-picker
      :columns="isOpenOps"
      :valueShow="isOpenShow"
      :defaultIndex="curItem.isOpen"
      :valueKey="'text'"
      @clickOverlay="clickIsOpenOverlay"
      @cancel="cancelIsOpen"
      @confirmPicker="confirmIsOpen"
    ></v-picker>

    <v-popup v-model="testTypeShow" :height="'auto'" :closeable="false">
      <div class="headerToolbar">
        <button type="button" class="cancel" @click="testTypeCancel">
          取消
        </button>
        <button type="button" class="confirm" @click="testTypeConfirm">
          确认
        </button>
      </div>
      <v-checkbox-group
        v-model="testType"
        :list="testTypeOps"
      ></v-checkbox-group>
    </v-popup>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getStationListUrl, updateStationUrl, getInitInfoUrl } from "./api";
import {
  waitingTimeMap,
  setWaitingTimeOps,
  isOpenMap,
  setIsOpenOps,
} from "./map";

export default {
  name: "epidemicList",
  data() {
    return {
      isAdmin: false, // 是否管理员
      colorMap: {
        1: "#ABEA7E",
        2: "#FFEE9E",
        3: "#FF2626E6",
      },
      waitingTimeMap,
      waitingTimeOps: setWaitingTimeOps(),
      listData: [],
      curItem: {},
      /* 下拉选项 */
      districtList: [
        { text: "全部社区", value: "" },
        // { text: "全部社区1", value: "1" },
        // { text: "全部社区2", value: "2" },
        // { text: "全部社区3", value: "3" },
        // { text: "全部社区4", value: "4" },
        // { text: "全部社区5", value: "5" },
        // { text: "全部社区6", value: "6" },
        // { text: "全部社区7", value: "7" },
        // { text: "全部社区8", value: "8" },
        // { text: "全部社区9", value: "9" },
      ],
      /* v-picker */
      showPicker: false,
      /* v-checkbox */
      testTypeOps: [
        {
          text: "鼻拭子",
          value: "鼻拭子",
        },
        {
          text: "咽拭子",
          value: "咽拭子",
        },
      ],
      testType: [],
      testTypeShow: false,
      /* v-picker */
      isOpenShow: false,
      isOpenMap,
      isOpenOps: setIsOpenOps(),
      /* v-list */
      finishedText: "没有更多了",
      finished: false,
      stationCloseCount: "",
      stationOpenCount: "",
      requestData: {
        lon: "",
        lat: "",
        districtName: "",
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  computed: {
    districtName() {
      return this.requestData.districtName;
    },
  },
  watch: {
    districtName: {
      handler() {
        this.requestData.curPage = 1;
        this.onLoad();
      },
      deep: true,
    },
    showPicker(val) {
      if (!val) {
        this.curItem = {};
        return;
      }
    },
    testTypeShow(val) {
      if (!val) {
        this.curItem = {};
        this.testType = [];
        return;
      }
    },
  },
  created() {
    this.getDistrictList();
    this.isAdmin = this.$route.query.admin !== "user";
    if (this.$route.query.isCode == 1) {
      this.getPosition(this.forPosition);
    } else {
      this.requestData.lon = this.$route.query.longitude;
      this.requestData.lat = this.$route.query.latitude;
      this.onLoad();
    }
  },
  methods: {
    forPosition(lng, lat) {
      this.requestData.lon = lng;
      this.requestData.lat = lat;
      this.onLoad();
    },
    getPosition(callback) {
      if ("geolocation" in navigator) {
        var geo_options = {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 6000,
        };
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            // 获取到当前位置经纬度
            var lng = pos.coords.longitude;
            var lat = pos.coords.latitude;
            console.log(pos, "===");
            callback(lng, lat);
          },
          (err) => {
            alert(err.message);
          },
          geo_options
        );
      } else {
        alert("Browser didnt support geolocation");
      }
    },
    change() {},
    onLoad() {
      this.getStationList();
    },
    setStyle(item) {
      if (this.isAdmin) {
        return {};
      } else {
        return {
          backgroundColor: this.colorMap[item.waitingTime],
          color: item.waitingTime === 3 ? "#fff" : "rgba(0, 0, 0, 0.85)",
        };
      }
    },
    getStationList() {
      let params = {
        // curPage: this.requestData.curPage,
        // pageSize: this.requestData.pageSize,
        // lat: this.requestData.lat,
        // lon: this.requestData.lon,
        // districtName: this.requestData.districtName,
        ...this.requestData,
        fromFlag: this.isAdmin ? 2 : 1,
        // userId: this.userId,
      };
      if (this.requestData.curPage === 1 && this.$refs.epidemicList) {
        // this.$nextTick(() => {
        // 回到顶部
        // this.$refs.epidemicList.scrollIntoView();
        document.querySelector("#app").scrollTop = 0;
        // });
      }
      this.$axios
        .get(getStationListUrl, { params })
        .then((res) => {
          this.stationCloseCount = res.data.stationCloseCount;
          this.stationOpenCount = res.data.stationOpenCount;
          if (this.requestData.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.requestData.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            // console.log(
            //   this.stationCloseCount,
            //   this.stationOpenCount,
            //   "jsjsjjj"
            // );
            this.requestData.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 更新防疫站点信息
    async updateStation(val, str) {
      let params = {
        id: this.curItem.id,
      };
      params[str] = val;
      const res = await this.$axios.post(updateStationUrl, params);
      console.log(res, "res---");
      if (res.code === 200) {
        this.$toast.success("操作成功！");
        return true;
      }
      return false;
    },

    /* v-dropDownMenu 选择 */
    getDistrictList() {
      // this.districtList = [];
      this.$axios.get(getInitInfoUrl).then((res) => {
        if (res.code === 200) {
          let result = [];
          res.data.districtNameList.forEach((item) => {
            result.push({
              text: item,
              value: item,
            });
          });
          this.districtList.push(...result);
        }
      });
    },
    /* v-picker 选择 */
    chooseWaitingTime(item) {
      if (!this.isAdmin) {
        this.toEpidemicDetail(item);
        return;
      }
      this.curItem = item;
      this.showPicker = true;
    },
    clickWaitingTimeOverlay() {
      this.showPicker = false;
    },
    cancelWaitingTime() {
      this.showPicker = false;
    },
    async confirmWaitingTime(val) {
      const result = await this.updateStation(val.value, "waitingTime");
      this.curItem.waitingTime = val.value;
      this.showPicker = !result;
    },
    /* v-picker 选择是否开放 */
    chooseIsOpen(item) {
      if (!this.isAdmin) {
        this.toEpidemicDetail(item);
        return;
      }
      this.curItem = item;
      this.isOpenShow = true;
    },
    clickIsOpenOverlay() {
      this.isOpenShow = false;
    },
    cancelIsOpen() {
      this.isOpenShow = false;
    },
    async confirmIsOpen(val) {
      const result = await this.updateStation(val.value, "isOpen");
      this.curItem.isOpen = val.value;
      this.isOpenShow = !result;
    },

    /* v-checkbox-group */
    chooseTestType(item) {
      if (!this.isAdmin) {
        this.toEpidemicDetail(item);
        return;
      }
      this.curItem = item;
      this.testTypeShow = true;
    },
    testTypeCancel() {
      this.testTypeShow = false;
    },
    async testTypeConfirm() {
      const testType = this.testType ? this.testType.join(",") : "";
      const result = await this.updateStation(testType, "testType");
      this.curItem.testType = testType;
      this.testTypeShow = !result;
    },
    // 跳转防疫详情
    toEpidemicDetail(item) {
      if (this.isAdmin) {
        return;
      }
      let params = {
        id: item.id,
        // addr: item.addr,
        // name: item.name,
        // openTime: item.openTime,
        // testType: item.testType,
        // topicId: item.topicId,
        // waitingTime: item.waitingTime,
        // isOpen: item.isOpen,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/epidemicDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.padding-top {
  padding-top: 194px !important;
}
.epidemicList {
  box-sizing: border-box;
  min-height: 100%;
  background: #f7f7f7;
  padding-top: 32px;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 194px;
    background: #f7f7f7;
    // box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.5);
    z-index: 2;
    .header-sum {
      padding-top: 32px;
      display: flex;
      align-items: center;
      height: 36px;
      font-size: 26px;
      color: rgba(0, 0, 0, 0.5);
      > div {
        flex: 1;
        text-align: center;
        img {
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          vertical-align: middle;
        }
      }
    }
  }
  .list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 40px 32px;
    .section {
      position: relative;
      padding: 32px;
      background: #fff;
      border-radius: 16px;
      display: flex;
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      .section-left {
        flex: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // text-align: center;
        .left-title {
          font-size: 36px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          line-height: 50px;
          margin-bottom: 12px;
        }
        .left-addr,
        .left-openTime,
        .left-testType {
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
          span,
          img {
            display: inline-block;
          }
          > img {
            margin-left: 8px;
            width: 32px;
            height: 32px;
            vertical-align: top;
          }
        }
      }
      .distance {
        position: absolute;
        right: 12px;
        top: 4px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
      .section-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .right-label {
          margin: 12px;
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
        .right-waitingTime,
        .right-open {
          span,
          img {
            display: inline-block;
          }
          > img {
            margin-left: 8px;
            width: 32px;
            height: 32px;
            vertical-align: top;
          }
        }
        .right-waitingTime {
          width: 216px;
          height: 54px;
          // line-height: 54px;
          font-size: 24px;
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          // span,
          // img {
          //   display: inline-block;
          // }
          // > img {
          //   margin-left: 8px;
          //   width: 32px;
          //   height: 32px;
          //   vertical-align: top;
          // }
        }
        .right-open {
          margin-top: 12px;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
          // span,
          // img {
          //   display: inline-block;
          // }
          // > img {
          //   margin-left: 8px;
          //   width: 32px;
          //   height: 32px;
          //   vertical-align: top;
          // }
        }
        .right-no-open {
          margin-top: 12px;
          > img {
            vertical-align: middle;
            overflow: hidden;
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
  .headerToolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    > button {
      &:first-child {
        color: #969799;
      }
      &:last-child {
        color: #576b95;
      }
      height: 100%;
      padding: 0 32px;
      font-size: 28px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
