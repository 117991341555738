import { mapHelper } from "@/utils/utils.js";
const waitingTime = [
  {
    value: 1,
    text: "0.5小时内",
  },
  {
    value: 2,
    text: "0.5小时至1小时",
  },
  {
    value: 3,
    text: "超过1小时",
  },
];
const { map: waitingTimeMap, setOps: setWaitingTimeOps } =
  mapHelper.setMap(waitingTime);

const isOpen = [
  {
    value: 0,
    text: "未开放",
  },
  {
    value: 1,
    text: "已开放",
  },
];

const { map: isOpenMap, setOps: setIsOpenOps } = mapHelper.setMap(isOpen);
export { waitingTimeMap, setWaitingTimeOps, isOpenMap, setIsOpenOps };
